import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './store'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core';


let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2EB054",
      light: '#57bf76',
      dark: '#207b3a',
      contrastText: '#fff',
    },
    secondary: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#b2b2b2",
      contrastText: "#2EB054"
    },
  },
  typography: {
    "fontFamily": `'Signika', sans-serif`,
   }
});

theme = responsiveFontSizes(theme)

ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

